<template>
	<div class="CircleFriends">
		<div class="container">
			<div class="head-top">
				<div class="head-top-nav">
					<div :class="navActive == item.id ? 'head-top-nav-item-active' : 'head-top-nav-item'"
						v-for="(item,index) in list" @click="onListNav(item.id,index)">
						{{ item.title }}
						<van-icon style="transform: rotate(90deg);margin-left: 8px;" name="play" color="#AAA" size="16"
							v-if="index == 0" @click.stop="areaListShow = true" />
					</div>
				</div>
				<div class="head-top-right" @click="$router.push({ name: 'AddCirle' })">
					<van-icon name="add" color="#3377FF" size="24" v-if="isCity()" />
					<van-icon name="add" color="#FF6969" size="24" v-else />
				</div>
				<div class="head-top-area-box" v-if="areaListShow" @click="areaListShow = false">
					<div class="head-top-area">
						<div :class="areaActive == index ? 'item-active' : 'area-item'" v-for="(item,index) in areaList"
							@click.stop="onAreaList(item,index)">
							{{item.name}}
						</div>
						<div class="head-top-area-arrow">
							<img src="@/assets/img/icon37.png" alt="">
						</div>
					</div>
				</div>
			</div>
			<!-- 内容 -->
			<w-list class="list-view sign-in-scroll" :loading="loading" :finished="finished" :is-empty="isEmpty"
				@onLoad="onLoad">
				<div class="middle-item" v-for="(item,index) in listBody" :key="item.moment_id"
					@click="$router.push({ name: 'CircleDetail',params:{id: item.moment_id}})">
					<div class="item-top">
						<div class="item-top-left">
							<div class="top-left-picture">
								<img :src="item.avatar" mode=""></img>
								<div class="top-left-picture-mark">
									<img src="@/assets/img/icon420.png" mode="" v-if="item.sex == 2"></img>
									<img src="@/assets/img/icon421.png" mode="" v-else></img>
								</div>
							</div>
							<div class="top-left-box">
								<div class="top-left-box-top">
									<div class="top-title">{{item.user_name}}</div>
									<div class="top-title-role">{{item.level_name}}</div>
								</div>
								<div class="top-left-box-bottom">
									<div class="bottom-title">
										<van-icon style="margin-right: 8rpx;" name="location" color="#3377FF" v-if="isCity()" />
										<van-icon style="margin-right: 8rpx;" name="location" color="#FF6969" v-else />
										{{item.district_name}}
									</div>
									<div class="bottom-time">{{item.create_time}}</div>
								</div>
							</div>
						</div>
						<div class="item-top-right" v-if="navActive != 3">
							<div class="item-top-right-title" v-if="item.is_subscribe == 0"
								@click.stop="onFollow(item.user_id,index)">关注</div>
							<div class="item-top-right-title title-active" v-else
								@click.stop="onCancelFollow(item.user_id,index)">已关注</div>
							<div class="item-top-right-sort" @click.stop="onReportShow(index)">
								<van-icon name="ellipsis" color="#AAAAA9" size="20" />
							</div>
						</div>
						<div class="item-top-right" v-else>
							<div class="item-top-right-delete" @click.stop="onDelete(item.moment_id,index)">删除</div>
						</div>
					</div>

					<div class="item-popup" v-if="item.reportShow" @click.stop="onReport(item,index)">
						<div class="item-popup-arrow">
							<img src="@/assets/img/icon37.png" alt="">
						</div>
						<div class="item-popup-box">
							<div class="popup-picture"><img src="@/assets/img/icon34.png" alt=""></div>
							<div class="popup-title">举报</div>
							<div class="popup-text">虚假信息、广告、与主题不符等</div>
						</div>
					</div>
					<div class="item-pictureList">
						<div class="item-pictureList-item" v-for="(vo, index) in item.photos"
							@click.stop="onPreview(item.photos, index)">
							<img :src="vo">
						</div>
					</div>
					<div class="item-title">
						<div :class="['item-content', {'item-content-hidden': item.showAll == false}]"
							v-html="item.content" :ref="`item-content-${index}`"></div>
						<div class="item-show-more" v-show="item.showMore" @click.stop="item.showAll = !item.showAll">
							{{item.showAll == true ? '收起' : '全文'}} {{isOverflow(index)}}
						</div>
					</div>
					<div class="item-activity"
						@click.stop="$router.push({ name: 'ActivityDetail',params:{activityId: item.activity_id}})">
						<div class="item-activity-picture">
							<img src="@/assets/img/icon38.png" alt="" v-if="isCity()">
							<img src="@/assets/img/icon60.png" alt="" v-else>
						</div>
						<div class="item-activity-type">#{{item.activity_group}}</div>
						<div class="item-activity-title">{{item.activity_title}}</div>
					</div>
					<div class="item-footer">
						<div class="item-footer-left"
							@click.stop="$router.push({ name: 'CirleDetailPraise',params:{id: item.moment_id}})">
							<div class="item-footer-left-wrapper">
								<!-- item.stars_data -->
								<div class="item-footer-left-wrapper-picture" v-for="(vo,inde) in item.stars_data">
									<img :src="vo.avatar" mode="">
								</div>
							</div>
							<div class="item-footer-left-text">{{item.stars}}人点赞</div>
						</div>
						<div class="item-footer-right">
							<div class="item-footer-right-box">
								<van-icon name="good-job-o" color="#777B81" size="18" v-if="item.is_star == 0"
									@click.stop="onPraise(item.moment_id,index)" />
								<van-icon name="good-job-o" color="#3377FF" size="18" v-else @click.stop="onCancelPraise(item.moment_id,index)" />
							</div>
							<!-- @click.stop="onShare(item)" -->
							<div class="item-footer-right-box">
								<van-icon name="share-o" color="#777B81" size="18" />
							</div>
							<div class="item-footer-right-box">
								<van-icon name="chat-o" color="#777B81" size="18" />
								<div class="item-footer-right-box-text" v-if="item.comments">{{item.comments}}</div>
							</div>
						</div>
					</div>
					<div class="popup" v-if="item.reportShow" @click.stop="onCancelReportShow(index)"></div>
					<van-popup v-model="isShowPicker">
						<div class="showPopup" @click.stop="">
							<div class="showPopup-box">
								<div class="showPopup-title">
									举报违规
								</div>
								<div class="showPopup-textarea">
									<textarea v-model="reason" placeholder="请输入举报原因"></textarea>
								</div>
							</div>
							<div class="showPopup-footer">
								<div class="showPopup-footer-left" @click.stop="isShowPicker = false">取消</div>
								<div class="showPopup-footer-right" @click.stop="onSubmit(index)">提交</div>
							</div>
						</div>
					</van-popup>
				</div>
			</w-list>
			<Share :loading="isShowShare" @Touch="share()"></Share>
		</div>
		<w-tabbar></w-tabbar>
	</div>
</template>

<script>
	import Circle from '@/api/circle';
	import Vant from '@/vendor/vant';
	import WList from '@/components/List';
	import Share from '@/components/Share';
	import Config from '@/vendor/config';
	import WTabbar from '@/components/Tabbar';
	import LoadingPage from '@/components/LoadingPage';
	import keepMiXin from '@/mixins/keep';
	import scrollMiXin from '@/mixins/scroll';
	
	const keep = keepMiXin(['CircleFriends']);
	const scroll = scrollMiXin(['.list-view .sign-in-scroll']);
	
	export default {
		name: "CircleFriends",
		mixins: [keep, scroll],
		inject: ['themeColor', 'className', 'isCity', 'isDistrict'],
		data() {
			return {
				list: [{
					id: 1,
					title: '全部'
				}, {
					id: 2,
					title: '关注'
				}, {
					id: 3,
					title: '我的发布'
				}],
				navActive: 1,
				areaList: [],
				areaActive: 0,
				areaListShow: false,
				current: 3,
				reportShow: false,
				followShow: false,
				isShowPicker: false,
				regionId: 1,
				listBody: [],
				page: 1,
				limit: 10,
				district_id: 0,
				reason: '',
				momentId: "",
				reportIndex: "",
				loading: true,
				finished: false,
				isEmpty: false,
				isShowShare: false,
				loadingPage: true,
			};
		},
		created() {
			this.$store.commit('keep/setKeep', 'CircleFriends');
			this.onLoad()
			Circle.districtList()
				.then(result => {
					let area = {
						name: '全部',
						district_id: 0
					}
					result.data.unshift(area)
					this.areaList = result.data
				}, error => {
					Vant.Toast.fail(error.msg)
				})
		},
		methods: {
			// 分享义警圈
			onShare(item) {
				this.isShowShare = true
				wx.onMenuShareTimeline({
					title: item.activity_title + '-' + document.title,
					link: Config.circleFriends + item.moment_id,
					imgUrl: Config.shareLogoUrl,
					success: () => {
						this.isShowShare = false;
						Circle.momentShare({
								moment_id: item.moment_id
							})
							.then(result => {
								if (result.data.state == 0) {
									Vant.Toast('您已经分享过了哦！');
								} else {
									Vant.Toast.success('分享成功爱心值+1');
								}
							}, error => {
								Vant.Toast.fail(error.msg)
							})
					},
					error: () => {
						console.log(12);
					}
				});

				wx.onMenuShareAppMessage({
					title: item.activity_title + '-' + document.title,
					link: Config.circleFriends + item.moment_id,
					imgUrl: Config.shareLogoUrl,
					success: () => {
						this.isShowShare = false;
						Circle.momentShare({
								moment_id: item.moment_id
							})
							.then(result => {
								if (result.data.state == 0) {
									Vant.Toast('您已经分享过了哦！');
								} else {
									Vant.Toast.success('分享成功爱心值+1');
								}
							}, error => {
								Vant.Toast.fail(error.msg)
							})
					},
					error: () => {
						console.log(12);
					}
				});

			},
			share() {
				this.isShowShare = false;
			},
			// 删除义警圈
			onDelete(id, index) {
				Vant.Dialog.confirm({
						title: '删除提示',
						message: '您确认删除这条动态吗？',
					})
					.then(() => {
						Circle.momentDel({
								moment_id: id
							})
							.then(result => {
								this.listBody.splice(index, 1)
								Vant.Toast('删除动态成功')
							}, error => {
								Vant.Toast.fail(error.msg)
							})
					})

			},
			// 判断文字是否需要收起展开
			isOverflow(index) {
				this.$nextTick(() => {
					const dom = this.$refs[`item-content-${index}`][0]
					const style = window.getComputedStyle(dom)
					this.listBody[index].showMore = dom.scrollHeight / parseInt(style.lineHeight) > 2
				})
			},

			onReportShow(index) {
				this.reportIndex = index
				this.listBody[index].reportShow = true
			},
			onCancelReportShow(index) {
				this.listBody[index].reportShow = false
			},
			// 举报
			onReport(item, index) {
				this.isShowPicker = true
				this.momentId = item.moment_id
			},
			onSubmit(index) {
				let params = {
					moment_id: this.momentId,
					type: 1,
					reason: this.reason
				}

				Circle.report(params)
					.then(result => {
						Vant.Toast.success('举报成功')
						this.reason = ''
						this.isShowPicker = false
						this.onCancelReportShow(this.reportIndex)
					}, error => {
						Vant.Toast.fail(error.msg)
					})
			},
			// 预览图片
			onPreview(vo, index) {
				Vant.ImagePreview({
					images: vo,
					startPosition: index,
				});
			},
			onAreaList(item, index) {
				this.district_id = item.district_id
				this.areaActive = index
				this.areaListShow = false
				this.list[0].title = item.name
				this.page = 1
				this.loading = true
				this.finished = false
				this.listBody = []
				this.onLoad()
			},
			onListNav(id, index) {
				this.navActive = id;
				this.page = 1
				this.loading = true
				this.finished = false
				this.listBody = []
				this.onLoad()
			},
			// 获取列表
			onLoad() {
				this.loading = true;
				let params = {
					page: this.page,
					limit: this.limit,
					type: this.navActive,
					district_id: this.district_id
				}
				Circle.lists(params)
					.then(result => {
						result.data.lists.forEach(item => {
							item.showAll = false
							item.showMore = false
							item.reportShow = false
						})
						this.page++
						this.listBody = this.listBody.concat(result.data.lists)
						this.loading = false;

						if (this.listBody.length >= result.data.total) {
							this.finished = true;
						}
						this.isEmpty = this.listBody.length <= 0;
					}, error => {
						Vant.Toast.fail(error.msg)
					})

			},
			// 点赞
			onPraise(moment_id, index) {
				Circle.starMoment({
					moment_id
				}).then(result => {
					this.listBody[index].is_star = 1
					this.listBody[index].stars++
					Vant.Toast.success('点赞成功')
				}, error => {
					Vant.Toast.fail(error.msg)
				})
			},
			// 取消点赞
			onCancelPraise(moment_id, index) {
				Circle.cancelStarMoment({
					moment_id
				}).then(result => {
					this.listBody[index].is_star = 0
					this.listBody[index].stars--
					Vant.Toast('取消点赞')
				}, error => {
					Vant.Toast.fail(error.msg)
				})
			},
			// 关注
			onFollow(subscribe_id, index) {
				Circle.subscribe({
					subscribe_id
				}).then(result => {
					this.listBody.forEach(item => {
						if (item.user_id == subscribe_id) {
							item.is_subscribe = 1
						}
					})
					Vant.Toast('关注成功')
				}, error => {
					Vant.Toast.fail(error.msg)
				})
			},
			// 取消关注
			onCancelFollow(subscribe_id, index) {
				Circle.cancelSubscribe({
					subscribe_id
				}).then(result => {
					this.listBody.forEach(item => {
						if (item.user_id == subscribe_id) {
							item.is_subscribe = 0
						}
					})
					Vant.Toast('取消关注')
				}, error => {
					Vant.Toast.fail(error.msg)
				})
			},

		},
		components: {
			WList,
			Share,
			WTabbar,
			LoadingPage
		}
	};
</script>

<style scoped lang="less">
	.CircleFriends.city {
		.head-top-nav {
			.head-top-nav-item-active {
				color: #3377FF;
			}
		}

		.head-top-area {
			.item-active {
				color: #3377FF;
			}
		}

		.middle-item {
			.top-left-box-bottom {
				.bottom-title {
					color: #3377FF;
				}
			}

			.item-top-right {
				.item-top-right-title {
					background: #3377FF;
				}
			}
			.item-show-more{
				color: #3377FF;
			}
			.item-activity{
				.item-activity-type{
					color: #3377FF;
				}
			}
		}
	}
	
	.CircleFriends.district {
		.head-top-nav {
			.head-top-nav-item-active {
				color: #FF6969;
			}
		}

		.head-top-area {
			.item-active {
				color: #FF6969;
			}
		}

		.middle-item {
			.top-left-box-bottom {
				.bottom-title {
					color: #FF6969;
				}
			}

			.item-top-right {
				.item-top-right-title {
					background: #FF6969;
				}
			}
			.item-show-more{
				color: #FF6969;
			}
			.item-activity{
				.item-activity-type{
					color: #FF6969;
				}
			}
		}
	}

	.CircleFriends {
		width: 100%;
		// min-height: 100vh;
		background: #f5f6f8;
		box-sizing: border-box;
		min-height: calc(100vh - 50px);
		height: calc(100vh - 50px);

		/deep/.van-overlay {
			background-color: rgba(0, 0, 0, .4);
		}

		.popup {
			width: 100%;
			min-height: 100vh;
			background-color: rgba(0, 0, 0, .3);
			position: fixed;
			top: 0;
			left: 0;
			z-index: 1001;
		}

		.container {
			padding: 16px 8px;
			padding-bottom: 0;
			box-sizing: border-box;
			min-height: calc(100vh - 50px);
			height: calc(100vh - 50px);

			.head-top {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 8px;

				.head-top-nav {
					display: flex;
					align-items: center;

					.head-top-nav-item {
						margin-right: 8px;
						font-size: 14px;
						line-height: 20px;
						color: #44484e;
						padding: 2px 8px;
						background-color: #fff;
						border-radius: 4px;
					}

					.head-top-nav-item-active {
						margin-right: 8px;
						font-size: 14px;
						line-height: 20px;
						padding: 2px 8px;
						background-color: #fff;
						border-radius: 4px;
					}
				}

				.head-top-area-box {
					position: absolute;
					top: 0;
					left: 0;
					background: rgba(0, 0, 0, 0.1);
					width: 100%;
					min-height: 100%;
					z-index: 1000;
					padding: 0 8px;
					box-sizing: border-box;

					.head-top-area {
						padding: 0 3px;
						background: #FFF;
						border-radius: 4px;
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						margin-top: 48px;
						padding-bottom: 8px;
						position: relative;

						.area-item {
							min-width: 58px;
							font-size: 14px;
							line-height: 20px;
							color: #777;
							padding: 8px 8px 0;
							box-sizing: border-box;
							text-align: center;


						}

						.item-active {
							min-width: 58px;
							font-size: 14px;
							line-height: 20px;
							padding: 8px 8px 0;
							box-sizing: border-box;
							text-align: center;
						}

						.head-top-area-arrow {
							position: absolute;
							top: -8px;
							left: 40px;
							width: 14px;
							height: 8px;

							img {
								position: absolute;
								z-index: 1001;
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}
					}

				}
			}

			.middle-item {
				margin-top: 8px;
				padding: 8px;
				background-color: #fff;
				border-radius: 8px;
				box-sizing: border-box;
				position: relative;

				.item-top {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.item-top-left {
						display: flex;
						align-items: center;

						.top-left-picture {
							position: relative;
							width: 40px;
							height: 40px;
							border-radius: 50%;

							img {
								width: 100%;
								height: 100%;
								border-radius: 50%;
								object-fit: cover;
							}

							.top-left-picture-mark {
								width: 22px;
								height: 22px;
								box-shadow: 0 0 2px rgba(0, 0, 0, 0.05);
								border-radius: 100px;
								position: absolute;
								bottom: -4px;
								right: -4px;
								z-index: 2;

								img {
									width: 100%;
									height: 100%;
									border-radius: 100px;
									object-fit: cover;
								}
							}
						}

						.top-left-box {
							padding-left: 6px;

							.top-left-box-top {
								display: flex;
								align-items: center;

								.top-title {
									font-weight: bold;
									font-size: 15px;
									line-height: 22px;
									color: #1c1d1d;
								}

								.top-title-role {
									font-size: 13px;
									line-height: 19px;
									color: #777b81;
									margin-left: 4px;
								}
							}

							.top-left-box-bottom {
								display: flex;
								align-items: center;

								.bottom-title {
									font-size: 12px;
									line-height: 17px;
								}

								.bottom-time {
									font-size: 12px;
									line-height: 17px;
									color: #777b81;
									margin-left: 4px;
								}
							}
						}
					}

					.item-top-right {
						display: flex;
						align-items: center;

						.item-top-right-delete {
							background: #F9615D;
							border-radius: 100px;
							font-size: 12px;
							color: #FFF;
							padding: 5px 20px;
						}

						.item-top-right-title {
							font-size: 12px;
							color: #fff;
							padding: 5px 20px;
							border-radius: 100px;
							margin-right: 4px;

							&.title-active {
								background: #F5F6F8;
								color: #777b81;
							}
						}

						.item-top-right-sort {
							padding: 3px;
							border-radius: 50%;
							background-color: #f5f6f8;
							text-align: center;
							transform: rotate(90deg);
						}
					}
				}

				.item-popup {
					width: 343px;
					background: #FFF;
					border-radius: 6px;
					position: absolute;
					z-index: 1002;
					top: 50px;
					left: 8px;

					.item-popup-arrow {
						width: 14px;
						height: 8px;
						position: absolute;
						top: -8px;
						right: 6px;

						img {
							position: absolute;
							z-index: 1001;
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.item-popup-box {
						display: flex;
						align-items: center;
						padding: 12px 0;
						padding-left: 12px;
						border-bottom: 1px dashed #F5F6F8;

						&.item-popup-box:last-child {
							border: 0;
						}

						.popup-picture {
							width: 24px;
							height: 24px;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.popup-title {
							font-size: 14px;
							line-height: 20px;
							color: #44484E;
							margin: 0 9px;
						}

						.popup-text {
							font-size: 12px;
							line-height: 17px;
							color: #777B81;
						}
					}
				}

				.item-pictureList {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					margin-top: 11px;

					.item-pictureList-item:last-child {
						margin-right: 0;
					}

					.item-pictureList-item:nth-child(3) {
						margin-right: 0;
					}

					.item-pictureList-item {
						width: 109px;
						height: 100px;
						border-radius: 2px;
						margin-right: 8px;
						margin-bottom: 8px;

						img {
							width: 100%;
							height: 100%;
							border-radius: 2px;
							object-fit: cover;
						}
					}
				}

				.item-content {
					font-size: 15px;
					line-height: 24px;
					color: #222;
					margin-top: 4px;

					&.item-content-hidden {
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}
				}

				.item-show-more {
					font-size: 15px;
					line-height: 24px;
				}

				.item-activity {
					display: flex;
					padding: 8px;
					background: #F2F2F2;
					border-radius: 4px;
					box-sizing: border-box;
					margin-top: 12px;

					.item-activity-picture {
						width: 16px;
						height: 16px;
						min-width: 16px;
						margin-right: 4px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.item-activity-type {
						font-size: 12px;
						margin-right: 11px;
						min-width: 58px;
					}

					.item-activity-title {
						font-size: 12px;
						color: #222;
					}
				}

				.item-footer {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-top: 12px;

					.item-footer-left {
						padding: 4px 8px;
						padding-left: 0;
						background: #F2F2F2;
						border-radius: 100px;
						display: flex;
						align-items: center;

						.item-footer-left-wrapper {
							display: flex;
							align-items: center;
							position: relative;
							padding-left: 18px;

							.item-footer-left-wrapper-picture {
								width: 22px;
								height: 22px;
								border: 2px solid #FFF;
								border-radius: 50%;
								margin-left: -10px;

								img {
									width: 100%;
									height: 100%;
									border-radius: 50%;
									margin-right: -10px !important;
								}
							}
						}

						.item-footer-left-text {
							font-size: 12px;
							line-height: 17px;
							color: #777;
							padding-left: 4px;
							text-align: right;
							box-sizing: border-box;
						}
					}

					.item-footer-right {
						display: flex;
						align-items: center;

						.item-footer-right-box:last-child {
							margin-right: 0;
						}

						.item-footer-right-box {
							display: flex;
							align-items: center;
							margin-right: 12px;
							padding: 7px 7px;
							background: #F2F2F2;
							border-radius: 100px;

							.item-footer-right-box-text {
								font-size: 12px;
								line-height: 17px;
								color: #777B81;
								margin-bottom: -3px;
								margin-left: 4px;
							}
						}
					}
				}
			}
		}

		.van-popup {
			border-radius: 6px;
			overflow: hidden;
		}

		.showPopup {
			background: #FFF;
			border-radius: 6px;
			overflow: hidden;

			.showPopup-box {
				padding: 15px;

				.showPopup-title {
					font-weight: bold;
					font-size: 17px;
					line-height: 28px;
					text-align: center;
					color: #222;
				}

				.showPopup-textarea {
					margin-top: 9px;

					textarea {
						width: 266px;
						height: 107px;
						background: #F2F2F2;
						border: 0;
						resize: none;
						padding-left: 5px;
					}
				}
			}

			.showPopup-footer {
				display: flex;
				align-items: center;

				.showPopup-footer-left {
					flex: 1;
					font-size: 17px;
					line-height: 28px;
					text-align: center;
					color: #FFF;
					background: #FF6969;
					padding: 8px 0;
				}

				.showPopup-footer-right {
					flex: 1;
					font-size: 17px;
					line-height: 28px;
					text-align: center;
					color: #FFF;
					background: #3377FF;
					padding: 8px 0;
				}
			}
		}
	}
</style>
